@import url('https://fonts.googleapis.com/css?family=Poppins');
body {
  margin: 0;
  
    font-family: "Poppins" !important;
}

code {
  font-family: "Poppins" !important;

  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', */
    /* monospace; */
}
*{
  font-family: "Poppins" !important;
}
