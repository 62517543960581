body {
  font-family: import poppins !important;
  padding: 0px !important;
  margin: 0px !important;
}

/* Typography{font-family:  !important;
} */
.Orangebtn {
  box-shadow: 0px 3.354px 11.179px 0px rgba(0, 0, 0, 0.05) !important;
  background-color: rgba(242, 111, 33, 1) !important;
  /* color: #fff; */
  padding: 20px 23px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;

}

.Orangebtn::before {
  color: white !important;
  content: "";
  position: absolute;
  /* background-color: rgba(244, 147, 58, 1) !important; */
  background-color: rgba(255, 255, 255, .1) !important;

  width: 10%;
  height: 25%;

  z-index: 1;
  top: 35%;
  left: 45%;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.Orangebtn:hover::before {
  -webkit-transform: scale(12);
  color: white !important;
  transform: scale(12);
  -webkit-transition: -webkit-transform 0.8s;
  transition: -webkit-transform 1s;
  transition: transform 0.8s;
  transition: transform 0.8s -webkit-transform 0.8s;
}

.Orangebtn:hover {
  color: white !important;
  background-color: #7cb74a !important;
}


.Orangebtn:hover::after {
  content: "";
  position: absolute;
  color: white !important;
  z-index: 2;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

/* greenbutton */
.Greenbtn {
  box-shadow: 0px 3.354px 11.179px 0px rgba(0, 0, 0, 0.05) !important;
  background-color: #6A9B41 !important;
  /* color: #fff; */
  /* padding: 20px 23px; */
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.Greenbtn::before {
  color: white !important;
  content: "";
  position: absolute;
  /* background-color: rgba(244, 147, 58, 1) !important; */
  background-color: rgba(255, 255, 255, .1) !important;

  width: 10%;
  height: 25%;

  z-index: 1;
  top: 35%;
  left: 45%;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.Greenbtn:hover::before {
  -webkit-transform: scale(12);
  color: white !important;
  transform: scale(12);
  -webkit-transition: -webkit-transform 0.8s;
  transition: -webkit-transform 1s;
  transition: transform 0.8s;
  transition: transform 0.8s -webkit-transform 0.8s;
}

.Greenbtn:hover {
  color: white !important;
}

.Greenbtn:hover::after {
  content: "";
  position: absolute;
  color: white !important;
  z-index: 2;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

/* WhiteButton */
.Whitebtn {
  box-shadow: 0px 3.354px 11.179px 0px rgba(0, 0, 0, 0.05) !important;
  background-color: #fff !important;
  color: #000000 !important; /* Text color */
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.Whitebtn::before {
  content: "";
  position: absolute;
  background-color: rgba(69, 75, 65, 0.1) !important; /* Greenish hover effect */
  width: 10%;
  height: 25%;
  z-index: 1;
  top: 35%;
  left: 45%;
  transform: scale(0);
}

.Whitebtn:hover::before {
  transform: scale(12);
  transition: transform 0.8s;
}

.Whitebtn:hover {
  color: #000000 !important; /* Text color on hover */
}

.Whitebtn:hover::after {
  content: "";
  position: absolute;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
}


.greybtn {
  /* background-color: rgba(236, 236, 236, 1) !important; */
  box-shadow: 0px 3.354px 11.179px 0px rgba(0, 0, 0, 0.05) !important;
  background-color: rgba(214, 214, 214, 0.45) !important;
  /* background-color: rgba(197, 197, 197, 1) !important; */
  color: black !important;
  padding: 20px 23px;
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.greybtn::before {
  content: "";
  position: absolute;
  /* background-color: rgba(197, 197, 197) !important; */
  background-color: rgba(255, 255, 255, .3) !important;
  width: 10%;
  height: 25%;

  z-index: 1;
  top: 35%;
  left: 45%;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.greybtn:hover::before {
  -webkit-transform: scale(12);
  transform: scale(12);
  -webkit-transition: -webkit-transform 0.8s;
  transition: -webkit-transform 1s;
  transition: transform 0.8s;
  transition: transform 0.8s -webkit-transform 0.8s;
}

.greybtn:hover::after {
  content: "";
  position: absolute;
  z-index: 2;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

@media screen and (max-width: 480px) {
  .checkbox .css-ahj2mt-MuiTypography-root {
    font-size: 12px !important;
  }
}

/* styling for steppers */
.MuiStepIcon-text {
  display: none !important;
}

.MuiStepLabel-iconContainer > .MuiSvgIcon-root{
  color: #8AC53E !important;
  font-size: 1rem !important;
}
.MuiSvgIcon-root.Mui-active{
  color: #F26F21 !important;
  font-size: 1.5rem !important;
}
/* .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #F26F21 !important;
  font-size: 1.5rem !important;

} */

.MuiStepConnector-line{
  border: 2px solid #8AC53E !important;
}

.MuiStep-root, .MuiStepLabel-iconContainer{
  padding: 0px !important;
}

/* .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root  */
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 11px !important;
}

/* .img-mobile {
    height: 70vh;
  } */

@media (max-width: 768px) {
  .img-desktop {
    height: 40vh;
  }
}

.css-18vync4-MuiDrawer-docked .MuiDrawer-paper {
  /* Styling for scrollbar */
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: #F4933A #D6EBCC;
  /* For Firefox */
  overflow-y: auto;
  /* Enable vertical scrollbar */
}

.css-18vync4-MuiDrawer-docked .MuiDrawer-paper::-webkit-scrollbar {
  width: 8px;
  /* Width of the scrollbar */
}

.css-18vync4-MuiDrawer-docked .MuiDrawer-paper::-webkit-scrollbar-thumb {
  background-color: #F4933A;
  /* Color of the thumb */
  border-radius: 4px;
  /* Rounded corners for the thumb */
  height: 30px;
}

.css-18vync4-MuiDrawer-docked .MuiDrawer-paper::-webkit-scrollbar-track {
  background-color: #D6EBCC;
  /* Color of the track */
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  border: none;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  background: transparent !important;
}

.dropdown-menu,
.dropdown-menu-up,
.dropdown-menu-down {
  display: block;
  position: absolute;
  min-width: 160px;
  z-index: 77;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.dropdown-item {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.css-heg063-MuiTabs-flexContainer {
  flex-wrap: wrap !important;
}


.dropdown-menu-down {
  position: absolute;
  top: 100%;
}

.dropdown-menu-up {
  position: absolute;
  bottom: 100%;
}


.space::before {
  display: none !important;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-input{
  display: none !important;
}
.go4109123758{
  justify-content: end !important;
  top: 12% !important;
  position: absolute;
}
#routeInfoDiv {
  display: flex;
  align-items: center; /* Vertically center items */
  
}

.css-dtt8oi::before {
  content: none !important;
  padding: 1px !important;
}

.bubble-img {
  display: block;
}

@media (max-width: 789px) {
  .bubble-img {
    display: none !important;
  }
}
@media (max-width: 600px) {
  .bubble-img {
    display: none !important;
  }
}

